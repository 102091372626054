.ql-toolbar .ql-stroke {
  fill: none !important;
  stroke: #fff !important;
}

.ql-toolbar .ql-fill {
  fill: #fff !important;
  stroke: none !important;
}

.ql-toolbar .ql-picker {
  color: #fff !important;
}
.ql-toolbar .ql-picker .ql-expanded {
  color: #0a0a0a !important;
}
.ql-toolbar .ql-picker .ql-active {
  color: #0a0a0a !important;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #050505 !important;
}
.ql-toolbar .ql-expanded .ql-stroke {
  fill: none !important;
  stroke: #080808 !important;
}
.ql-toolbar .ql-expanded .ql-fill {
  fill: #0e0d0d !important;
  stroke: none !important;
}
.ql-snow .ql-picker .ql-picker-options {
  color: #050505 !important;
}
.ql-snow .ql-align .ql-picker-options .ql-stroke {
  fill: none !important;
  stroke: #080808 !important;
}

.ql-toolbar .ql-active .ql-stroke {
  fill: none !important;
  stroke: #080808 !important;
}
.ql-toolbar .ql-active .ql-fill {
  fill: #0e0d0d !important;
  stroke: none !important;
}
.ql-snow .ql-align .ql-picker-options .ql-fill {
  fill: #0e0d0d !important;
  stroke: none !important;
}

.textEditor .quill {
  border: 1px solid #cce6fe;
}
.ql-container.ql-snow {
  border-color: transparent !important;
}
.ql-align-center {
  text-align: center !important;
}

.ql-align-left {
  text-align: left !important;
}

.ql-align-right {
  text-align: right !important;
}

.ql-align-justify {
  text-align: justify !important;
}

#custom {
  width: 7.5% !important;
  height: 7.5% !important;
  border: none !important ;
  box-sizing: border-box !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before {
  font-weight: 700;
  content: 'T' !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border: none !important;

  content: 'T' !important;
}

#custom svg {
  display: none;
}

.ql-format svg {
  height: 5px !important;
  width: 5px !important;
}

.ql-undo:active .ql-stroke,
.ql-redo:active .ql-stroke {
  fill: none !important;
  stroke: #080808 !important;
}
.ql-undo:active .ql-fill,
.ql-redo:active .ql-fill {
  fill: #0e0d0d !important;
  stroke: none !important;
}

#toolbar .ql-picker-label {
  display: block !important;
  /* width: 7.5% !important; */
  box-sizing: border-box !important;
}

#toolbar button {
  box-sizing: border-box !important;
  width: 7.5% !important;

  /* background-color: rgb(243, 81, 17) !important; */
}
